import axios, { AxiosError } from 'axios'
import Env from '../../../../utils/env'
import { disconnectSocket } from './socket'
import { toast } from 'react-toastify'
import i18n from 'i18next'

export const api = axios.create({
  baseURL: `${Env.backend}`
})

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const { status, config } = error.response || {}
    const requestUrl = config?.url

    if (status === 401) {
      if (requestUrl !== 'login') {
        window.location.href = '/login'
        localStorage.removeItem('@realize: accessToken')
        localStorage.removeItem('@realize: user')
        localStorage.removeItem('@realize: currentPage')
        disconnectSocket()
        toast.error(
          i18n.t(
            'Your connection was opened in another browser, if not, please contact support.'
          )
        )
      }
    }

    return Promise.reject(error)
  }
)

api.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  (error: AxiosError) => {
    // Check if theres any cancellation request
    if (axios.isCancel(error)) {
      console.warn(
        'Request canceled:',
        `${error?.config?.baseURL}${error?.config?.url} ${error.message}`
      )
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

export default api
