import {
  getThemeById,
  updateTheme,
  applyTheme,
  ThemeConfigFile
} from '@api/Themes'
import React, { createContext, useContext, useState } from 'react'
import { toast } from 'react-toastify'

export interface IResource {
  id: number
  slug: string
  name: string
  theme: Record<string, unknown>
  default: boolean
}

interface Theme {
  id: string
  name: string
  resources: IResource[]
  thumbnail: string
  config: ThemeConfigFile
}

interface ThemesContextData {
  currentTheme: Theme | null
  loadTheme: (id: string) => Promise<void>
  updateCurrentTheme: (id: string, data: Theme) => Promise<void>
  applyThemeToEntity: (entityId: string) => Promise<void>
  handleCreateResource: (slug: string, newName: string) => Promise<void>
  handleDeleteResource: (id: number) => void
  handleDuplicateResource: (resource: IResource) => void
  handleRenameResource: (id: number, newName: string) => void
  isLoading: boolean
}

const ThemesContext = createContext<ThemesContextData | undefined>(undefined)

export const ThemesProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [currentTheme, setCurrentTheme] = useState<Theme | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  // IMPLEMENTAR SWR
  const loadTheme = async (id: string) => {
    try {
      setIsLoading(true)
      const response = await getThemeById(id)
      setCurrentTheme(response.data)
      setIsLoading(false)
    } catch (error) {
      console.error('Erro ao carregar tema:', error)
      setIsLoading(false)
    }
  }

  const updateCurrentTheme = async (id: string, data: Theme) => {
    try {
      await updateTheme(id, data)
      loadTheme(id)
    } catch (error) {
      console.error('Erro ao atualizar o tema:', error)
    }
  }

  const applyThemeToEntity = async (entityId: string) => {
    if (!currentTheme) {
      console.error('Nenhum tema selecionado para aplicar')
      return
    }
    try {
      await applyTheme(currentTheme.name, entityId)
    } catch (error) {
      console.error('Erro ao aplicar o tema à entidade:', error)
    }
  }
  const handleRenameResource = async (id: number, newName: string) => {
    const resourceToRename = currentTheme?.resources.find(
      (resource) => resource.id === id
    )
    if (
      resourceToRename?.default === true ||
      !resourceToRename ||
      !currentTheme
    )
      return
    resourceToRename.name = newName
    const newTheme = {
      ...currentTheme,
      resources: currentTheme.resources.map((resource) =>
        resource.id === id ? resourceToRename : resource
      )
    }
    await updateCurrentTheme(currentTheme.id, newTheme)
  }

  const handleCreateResource = async (slug: string, newName: string) => {
    const originalResource = currentTheme?.resources.find(
      (resource) => resource.slug === slug
    )
    if (!originalResource || !currentTheme) return
    const newTheme = {
      ...currentTheme,
      resources: [
        ...currentTheme.resources,
        {
          ...originalResource,
          default: false,
          id: currentTheme.resources.length + 1,
          name: newName,
          slug: originalResource?.slug
        }
      ]
    }
    await updateCurrentTheme(currentTheme.id, newTheme)
  }

  const handleDeleteResource = (id: number) => {
    if (!currentTheme) return
    const resourceToDelete = currentTheme.resources.find(
      (resource) => resource.id === id
    )
    if (resourceToDelete?.default) {
      toast.error('Não é possível deletar um recurso padrão')
      return
    }
    const newTheme = {
      ...currentTheme,
      resources: currentTheme.resources.filter((resource) => resource.id !== id)
    }
    updateCurrentTheme(currentTheme.id, newTheme)
  }

  const handleDuplicateResource = (resource: IResource) => {
    handleCreateResource(resource.slug, `${resource.name} cópia`)
  }

  return (
    <ThemesContext.Provider
      value={{
        currentTheme,
        isLoading,
        loadTheme,
        updateCurrentTheme,
        applyThemeToEntity,
        handleCreateResource,
        handleDeleteResource,
        handleDuplicateResource,
        handleRenameResource
      }}
    >
      {children}
    </ThemesContext.Provider>
  )
}

export const useThemeContext = () => {
  const context = useContext(ThemesContext)
  if (!context) {
    throw new Error('useTheme deve ser usado dentro de um ThemesProvider')
  }
  return context
}
